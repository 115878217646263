<template>
    <div>
        <card-header title="Hotel" icon="fa-hotel"/>
        <card-toolbar>
            <button @click="$openCard('edit-trip-hotel', {hotelId: hotel.id, tripId: hotel.trip_id}, card)"><i class="fas fa-pencil mr-2"/>Edit Hotel</button>
            <button @click="$openCard('new-room-type', {hotelId: hotel.id, tripId: hotel.trip_id}, card)"><i class="fas fa-plus mr-2"/>New Room Type</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <subheader-list-item title="Hotel Details" icon="fa-list"/>
                <data-row title="Name">{{ hotel.title }}</data-row>
                <data-row title="Location">{{ hotel.location }}</data-row>

                <separator-list-item/>

                <subheader-list-item title="Room Types" icon="fas fa-bed"/>

                <draggable v-model="hotel.room_types" handle=".drag-handle" @end="completeDrag">
                    <transition-group>
                        <trip-room-type-list-item
                            :card="card"
                            :room="room"
                            :key="room.id"
                            :active="(child && child.definition.component === 'edit-room-type') && child.props.answerId === room.id.toString()"
                            @click="$openCard('edit-room-type', {tripId: hotel.trip_id, hotelId: hotel.id, roomId: room.id}, card)"
                            v-for="room in hotel.room_types"
                            class="t-card-list-item"
                        />
                    </transition-group>
                </draggable>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import CardToolbar from "@/TIER/components/CardToolbar";
    import SearchListItem from "@/TIER/components/SearchListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import NavigationListItem from "@/TIER/components/NavigationListItem";
    import TripListItem from "@/components/TripListItem";

    import draggable from 'vuedraggable';

    import {client as http} from '@/http_client';
    import CardBody from "@/TIER/components/CardBody";
    import DayListItem from "@/components/DayListItem";
    import TripAddOnListItem from "@/components/TripAddOnListItem";
    import QuestionListItem from "@/components/QuestionListItem";
    import DataRow from "@/TIER/components/DataRow";
    import AnswerListItem from "@/components/AnswerListItem";
    import TripRoomTypeListItem from "@/components/TripRoonTypeListItem";

    export default {
        name: "RegistrationQuestions",
        props: ['card', 'child', 'props'],
        components: {TripRoomTypeListItem, draggable, AnswerListItem, DataRow, QuestionListItem, TripAddOnListItem, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                hotel: {
                    responses: []
                }
            };
        },
        methods: {
            loadHotel: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/hotels/' + this.props.hotelId, {force}).then(response => {
                    this.hotel = response.data;
                    this.$emit('loading', false);
                });
            },
            completeDrag: function() {
                http.post('/api/trips/'  + this.props.tripId + '/hotels/' + this.props.hotelId + '/order', this.hotel.room_types.map(p => p.id)).then(response => {

                });
            },
            reload: function() {
                this.loadHotel(true);
            }
        },
        watch: {
            'props.hotelId': function() {
                this.loadHotel(true);
            }
        },
        mounted() {
            this.loadHotel(true);
        }
    };
</script>

<style lang="scss" scoped>

</style>
